import {XAxis,CartesianGrid,Tooltip,ResponsiveContainer,YAxis,LineChart,Line,} from "recharts";


const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        fontSize="0.5em"
        transform="rotate(-35)"
      >
        {payload.value}
      </text>
    </g>
  );
};

const LineCharts = ({ data, title, aspect }) => {
  return <div className="chart">
    <div className="title">{title}</div>
    <ResponsiveContainer aspect={aspect}>
      <LineChart width={730}
        height={350}
        data={data}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" tick={<CustomizedAxisTick />} />
        <YAxis />
        <Tooltip />
        <Line type="monotone" dataKey="count" stroke="#8884d8"></Line>
      </LineChart>
    </ResponsiveContainer>
  </div>
}

export default LineCharts