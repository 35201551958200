import { useQuery } from "react-query";

import { useState } from "react";
import axios from "../apis/axios";
import { config } from "../config";
import Cookies from "universal-cookie";

const cookies = new Cookies();

function useRead({
  url,
  initialData,
  method,
  onSuccess = () => {},
  onError = () => {},
}) {
  const [paramsObject, setGetListParams] = useState(initialData);
  const data = useQuery([url, paramsObject], () => getUsers(paramsObject), {
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: (err) => {
      onError?.(err);
    },
  });
  const getUsers = async (paramsObject) => {
    let token = cookies.get("_res_token");
    let fd = new FormData();
    for (var key in paramsObject) {
      fd.append(key, paramsObject[key]);
    }
    fd.append("token", JSON.stringify(token));
    fd.append("identity", config.identity);
    const response = await axios({
      method: method ? method : "POST",
      data: fd,
      headers: {
        Authorization: `Bearer ${token.token}`,
      },
      url: `/${url}`,
    });
    return response.data;
  };
  return { paramsObject, setGetListParams, data };
}

export default useRead;
