import React from 'react'
import Modal from '@mui/material/Modal';
import useCreate from '../../hooks/useCreate';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

const DeleteBlog = ({
  open,
  onClose,
  id
}) => {
  const navigate = useNavigate();
  const { setDataToServer } = useCreate({
    url: 'delete-blog',
    refreshUrl: 'blogsListing',
    onSuccess: (res) => {
      navigate(-1)
      toast.success('Blog Deleted Successfully')
    },
    onError: (err) => {
      alert('There Was an error while deleting the post please try again later')
    }
  })
  const handleDelete = () => {
    setDataToServer.mutate({ id })
  }
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className=' bg-white rounded-md shadow-lg w-[40%] absolute top-[1%] left-1/2 -translate-x-1/2'>
        <div className=' py-10'>
          <h1 className=' text-center font-semibold text-xl py-3'>Are you sure you want to delete this post?</h1>
          <div className=' text-center space-x-5'>
            <button onClick={handleDelete} type='button' className=' bg-[color:var(--primaryColor)] rounded-md px-6 py-2 text-white'>Delete</button>
            <button onClick={onClose} type='button' className=' border border-[color:var(--primaryColor)] rounded-md px-6 py-2'>Close</button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteBlog