import Layout from "../../components/layout"
import { useEffect, useState } from "react"
import { getData } from "../../apis"
import Moment from 'react-moment';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Modal } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import useGetSingleData from "../../hooks/useGetSingleData";

const FeedBacks = () => {
    const initialState = {
        screen_name: 'FEEDBACK',
        offset: 10,
        pageNo: 0
    }
    const [formData, setFormData] = useState(initialState)
    const [feedbackData, setFeedBackData] = useState([])
    const [dataCount, setDataCount] = useState(0)
    const [openFeedBackModal, setOpenFeedBackModal] = useState(false)
    const { data, handleGetSingleData } = useGetSingleData()
    useEffect(() => {
        getUserData()
    }, [])
    const getUserData = () => {
        getData(formData)
            .then((res) => {
                if (res.status === 200) {
                    setFeedBackData(res.data.data)
                    setDataCount(res.count)
                }
            })
            .catch((err) => {
                console.error(err)
            })
    }
    const handleGetFeedBackData = (id) => {
        handleGetSingleData(id, feedbackData)
    }
    const handleOpenFeedbackModal = (id) => {
        handleGetFeedBackData(id)
        setOpenFeedBackModal(true)
    }
    return <>
        <Layout>
            <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mt-8">
                <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                    <thead>
                        <tr className="text-left">
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                Sr.no
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                Username
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                Created
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                Status
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                Reply
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {feedbackData?.map((data, idx) => {
                            const { username, created_at, reply_status, reply_message, id } = data
                            return <tr key={idx}>
                                <td className="border-dashed border-t border-gray-200 userId">
                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{idx + 1}</span>
                                </td>
                                <td className="border-dashed border-t border-gray-200 firstName">
                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{username}</span>
                                </td>
                                <td className="border-dashed border-t border-gray-200 lastName">
                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                        <Moment date={created_at} fromNow />
                                    </span>
                                </td>
                                <td className="border-dashed border-t border-gray-200 emailAddress">
                                    <span className={`px-5 py-1 rounded text-sm font-semibold inline-block ${reply_status === 'PENDING' ? 'text-yellow-700 bg-yellow-200' : ''}`}>
                                        {reply_status}
                                    </span>
                                </td>
                                <td className="border-dashed border-t border-gray-200 emailAddress">
                                    <span className={`px-5 py-1 rounded text-sm font-semibold inline-block ${reply_message === 'NOT REPLIED' ? 'text-red-600 bg-red-200' : ''}`}>
                                        {reply_message}
                                    </span>
                                </td>
                                <td className="border-dashed border-t border-gray-200 emailAddress py-2">
                                    <button onClick={() => handleOpenFeedbackModal(id)} className="px-4 py-2 rounded bg-gray-300"><RemoveRedEyeIcon /> View</button>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </Layout>
        <Modal open={openFeedBackModal} onClose={() => setOpenFeedBackModal(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <div className='answerModal-container h-full'>
                <div className='answerModal-row max-w-4xl mx-auto mt-10'>
                    <div className='bg-white rounded-md px-4 document-wrapper divide-y'>
                        <div className="modalHead document-head py-4">
                            <div className='flex items-center justify-between'>
                                <h2 className=' text-gray-700 text-3xl font-semibold'>Feedback</h2>
                                <div className='w-6 h-6 cursor-pointer' onClick={() => setOpenFeedBackModal(false)}>
                                    <ClearIcon className='w-full h-full' />
                                </div>
                            </div>
                        </div>
                        <div className=" max-h-[30rem] overflow-auto">
                            <form action="" className=" ">
                                <div className=" py-4 space-y-4">
                                    <div className="grid grid-cols-3 gap-3">
                                        <div>
                                            <label htmlFor="username" className="label">User Name</label>
                                            <p className="text">{data.username}</p>
                                        </div>
                                        <div>
                                            <label htmlFor="username" className="label">Created at</label>
                                            <p className="text"><Moment date={data.created_at} fromNow /></p>
                                        </div>
                                        <div>
                                            <label htmlFor="username" className={`label block`}>Status</label>
                                            <p className={`text inline-block px-4 py-1 rounded ${data.reply_status === 'PENDING' ? 'text-yellow-700 bg-yellow-200' : ''}`}>{data.reply_status}</p>
                                        </div>
                                    </div>
                                    <div className="space-y-4">
                                        <div>
                                            <label htmlFor="username" className="label">Request</label>
                                            <p className="text">{data.request}</p>
                                            <textarea className="text_area" readOnly disabled value={data.request} placeholder="Request"></textarea>
                                        </div>
                                        <div>
                                            <label htmlFor="username" className="label">Complaint</label>
                                            <textarea className="text_area" readOnly disabled value={data.complaint} placeholder="Complaint"></textarea>
                                        </div>
                                        <div>
                                            <label htmlFor="username" className="label">Suggestion</label>
                                            <textarea className="text_area" readOnly disabled value={data.suggestions} placeholder="Suggestion"></textarea>
                                        </div>
                                        <div>
                                            <label htmlFor="username" className="label">Write a Reply</label>
                                            <textarea className="text_area" placeholder="Write Your Reply Here...."></textarea>
                                        </div>
                                        <div>
                                            <label htmlFor="username" className="label mr-3">Notify User</label>
                                            <input type="checkbox" name="" id="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="modalFoot py-4 border-t">
                                    <div className=' space-x-4'>
                                        <button className='px-8 py-2 rounded bg-[color:var(--primaryColor)] text-white'>Verify</button>
                                        <button type="button" className='px-8 py-2 rounded border'>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </>
}

export default FeedBacks



