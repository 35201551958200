import { useMutation, useQuery } from "react-query";

import { useState } from "react";
import { config } from "../config";
import Cookies from "universal-cookie";
import axios from "axios";

const cookies = new Cookies();

function useFetchYoutubeVideos({
  initialData,
  method,
  onSuccess = () => {},
  onError = () => {},
}) {
  const reqObject = useMutation((params) => getData(params), {
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: (err) => {
      onError?.(err);
    },
  });

  const createGetUrl = (url, reqParams) => {
    let finalUrl = url;
    let counter = 0;

    for (let [key, value] of Object.entries(reqParams)) {
      if (counter == 0) {
        finalUrl += "?";
      } else {
        finalUrl += "&";
      }

      finalUrl += key + "=" + value;
      counter++;
    }

    return finalUrl;
  };

  const getData = async (paramsObject) => {
    let finalUrl = createGetUrl(
      "https://www.googleapis.com/youtube/v3/playlistItems",
      paramsObject
    );

    const response = await axios({
      method: "GET",
      url: finalUrl,
    });

    return response.data;
  };
  return reqObject;
}

export default useFetchYoutubeVideos;
