import { useMutation, useQueryClient } from "react-query";
import axios from "../apis/axios";
import Cookies from "universal-cookie";
import { config } from "../config";

const cookies = new Cookies();
function useCreate({ refreshUrl = "", url = "", onSuccess, onError }) {
  const queryClient = useQueryClient();
  const setDataToServer = useMutation((data) => setUsers(data), {
    onError: (err) => {
      onError?.(err);
    },
    onSuccess: (data) => {
      {
        refreshUrl && queryClient.invalidateQueries(refreshUrl);
      }
      onSuccess?.(data);
    },
  });
  const setUsers = async (getFormData) => {
    let fd = new FormData();
    let token = cookies.get("_res_token");
    for (var key in getFormData) {
      if (
        Array.isArray(getFormData[key]) ||
        typeof getFormData[key] === "object"
      ) {
        fd.append(key, JSON.stringify(getFormData[key]));
      } else {
        fd.append(key, getFormData[key]);
      }
    }
    fd.append("token", JSON.stringify(token));
    fd.append("identity", config.identity);
    const response = await axios({
      method: "POST",
      data: fd,
      headers: {
        Authorization: `Bearer ${token.token}`,
      },
      url: `/${url}`,
    });
    return response.data;
  };
  return { setDataToServer };
}

export default useCreate;
