// "https://api.ambrosianresources.in/"
const apiUrls = {
  production: "https://respondo.io/",
  preprod: "https://uat-apis.respondo.com.au/",
  stage: "https://sapi.respondo.com.au/",
};

export const ENVIRONMENT = "production"; // stage | preprod | production
export const config = {
  token:
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NTg3NTAwMzUsIm5iZiI6MTY1ODc1MDA0NSwiZXhwIjoxNjU5NjE0MDM1LCJ1c2VybmFtZSI6ImFkbWluaXN0cmF0b3IiLCJpZCI6dHJ1ZSwiaXNzIjoiVGVhbU5nYWdlIiwiYXVkIjoiVGVhbU5nYWdlIn0.9iciZeKjkEIZRB7Oe0UEMZM46O_2fOaRki7LbnmTmhw",
  role: "admin",
  role: "member",
  apiurl: apiUrls[ENVIRONMENT],
  identity: "native",
};
