import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./style/dark.scss";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import FeedBacks from "./pages/feedbacks";
import UsersReports from "./pages/reports/UsersResport";
import PostsReport from "./pages/reports/PostsReport";
import Users from "./pages/users";
import Posts from "./pages/posts";
import Blogs from "./pages/blogs";
import { QueryClient, QueryClientProvider, } from 'react-query'
import CreateBlog from "./pages/blogs/CreateBlog";
import EditBlog from "./pages/blogs/EditBlog";
import Outsourced from "./pages/outsourced";
import InstagramApplications from "./pages/outsourced/InstagramApplications";
import YoutubeApplications from "./pages/outsourced/YoutubeApplications";
import YoutubeSingleApplication from "./pages/outsourced/YoutubeSingleApplication";


const queryClient = new QueryClient()
function App() {
  const { darkMode } = useContext(DarkModeContext);
 
  return (
    <div className={darkMode ? "app dark" : "app"}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path="/">
              <Route index element={<ProtectedRoutes><Home /></ProtectedRoutes>} />
              <Route path="login" element={<Login />} />
              <Route path="users" element={<ProtectedRoutes><Users /></ProtectedRoutes>} />
              <Route path="feedbacks" element={<ProtectedRoutes><FeedBacks /></ProtectedRoutes>} />
              <Route path="reported-users" element={<ProtectedRoutes><UsersReports /></ProtectedRoutes>} />
              <Route path="reported-posts" element={<ProtectedRoutes><PostsReport /></ProtectedRoutes>} />
              <Route path="posts" element={<ProtectedRoutes><Posts /></ProtectedRoutes>} />
              <Route path="blogs" element={<ProtectedRoutes><Blogs /></ProtectedRoutes>} />
              {/* <Route path="outsourced" element={<ProtectedRoutes><Outsourced /></ProtectedRoutes>} /> */}
              {/* <Route path="outsourced-instagram" element={<ProtectedRoutes><InstagramApplications /></ProtectedRoutes>} /> */}
              {/* <Route path="outsourced-youtube" element={<ProtectedRoutes><YoutubeApplications /></ProtectedRoutes>} /> */}
              {/* <Route path="outsourced-yt-application/:id" element={<ProtectedRoutes><YoutubeSingleApplication /></ProtectedRoutes>} /> */}
              <Route path="create-blog" element={<ProtectedRoutes><CreateBlog /></ProtectedRoutes>} />
              <Route path="edit-blog/:id" element={<ProtectedRoutes><EditBlog /></ProtectedRoutes>} />
            </Route>
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </div>
  );
}

export default App;
