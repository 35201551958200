import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./home.scss";
import Widget from "../../components/widget/Widget";
import Featured from "../../components/featured/Featured";
import Chart from "../../components/chart/Chart";
import Table from "../../components/table/Table";
import { useEffect } from "react";
import { getDashboardData } from "../../apis";
import { useState } from "react";
import LineCharts from "../../components/chart/linechart";

const Home = () => {
  const [dashboardData, setDashboardData] = useState({})
  useEffect(() => {
    fetchDashboardData()
  }, [])
  const fetchDashboardData = () => {
    getDashboardData()
      .then((res) => {
        if (res.status === 200) {
          setDashboardData(res.data.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const { total_users, total_posts,total_videos_views,new_users,sign_up_graph,most_followed_user,posts_graph } = dashboardData
  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <div className="p-4">
          <div className="grid grid-cols-4 gap-4">
            <Widget type="user" count={total_users} />
            <Widget type="posts" count={total_posts} />
            <Widget type="new_user" count={new_users} />
            <Widget type="most_views" count={total_videos_views} />
            <Widget type="most_followed" count={most_followed_user} />
            {/* <Widget type="followed_user" count={total_users} /> */}
          </div>
        </div>
        <div className="">
          <div className="grid grid-cols-2 gap-x-3 px-4">
            {/* <Chart title="User Registrations" aspect={2 / 1} data={sign_up_graph}/> */}
            {/* <Chart title="Logged in user" aspect={2 / 1} /> */}
            <LineCharts data={sign_up_graph} title="User Registrations" aspect={2 / 1}/>
            <LineCharts data={posts_graph} title="Videos" aspect={2 / 1}/>
          </div>
        </div>
        {/* <div className="listContainer">
          <div className="listTitle">Latest Transactions</div>
          <Table />
        </div> */}
      </div>
    </div>
  );
};

export default Home;
