import axios from 'axios';
import { config } from "../config";

export default axios.create({
    baseURL: config.apiurl
});

// export const axiosPrivate = axios.create({
//     baseURL: API_ENDPOINT,
//     headers:{
//         "Content-Type": "application/json",
//     },
// });