import React from 'react'
import Layout from '../../components/layout'
import SearchBar from '../../components/SearchBar'
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import useRead from '../../hooks/useRead';
import toast, { Toaster } from 'react-hot-toast';
import spinner from '../../assets/images/loader.gif'
import Moment from 'react-moment';
import EditIcon from '@mui/icons-material/Edit';
import ReactPaginate from 'react-paginate';

const Blogs = () => {
  let initialState = {
    limit: 10,
    pageNo: 0,
    search_query: ''
  }
  const { data: blogList, paramsObject, setGetListParams } = useRead({
    url: 'blogsListing',
    initialData: initialState,
    onError: (err) => {
      toast.error('There was an error while loading the data')
    }
  })

  const handlePageClick = (val) => {
    let data = {
        ...paramsObject,
        pageNo: val.selected
    }
    setGetListParams(data)
}

const handleSearch = (e) => {
    let value = e.target.value
    let data = {
        ...paramsObject,
        search_query: value
    }
    setGetListParams(data)
}
  return (
    <Layout>
      <Toaster />
      <div className='flex items-end space-x-16'>
        <div className='w-full flex-1'>
          <SearchBar name='username' placeholder='Search by title' value={paramsObject.search_query} onChange={handleSearch}/>
        </div>
        <Link to={`/create-blog`} className='bg-blue-600 text-white px-12 py-2 rounded h-full'>
          <div className=' space-x-2 items-center'>
            <AddIcon />
            <span>Add Blog</span>
          </div>
        </Link>
      </div>
      {blogList.isLoading
        ? <div className=' flex items-center justify-center mt-10'>
          <img src={spinner} alt="spinner" className=' w-8 h-8' />
        </div>
        :
        <>
          <div className="overflow-x-auto  bg-white rounded-lg shadow overflow-y-auto relative mt-8">
            <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
              <thead>
                <tr className="text-left">
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                    Sr.no
                  </th>
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                    Title
                  </th>
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                    Published on
                  </th>
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                    Created on
                  </th>
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                    Status
                  </th>
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                    Action
                  </th>
                </tr>
              </thead>
              {blogList.data?.data.map((item, idx) => {
                const { title, published_at, created_on, display_status, id } = item
                return <tr key={idx}>
                  <td className="border-dashed border-t border-gray-200 userId">
                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{(paramsObject.limit * (paramsObject.pageNo + 1)) - (paramsObject.limit - (idx + 1))}</span>
                  </td>
                  <td className="border-dashed border-t border-gray-200 firstName">
                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm font-semibold">{title}</span>
                  </td>
                  <td className="border-dashed border-t border-gray-200 firstName">
                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                      {published_at
                        ? <Moment unix date={published_at} format='ll' />
                        : '----'}
                    </span>
                  </td>
                  <td className="border-dashed border-t border-gray-200 firstName">
                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                      <Moment unix date={created_on} format='ll' />
                    </span>
                  </td>
                  <td className="border-dashed border-t border-gray-200 firstName">
                    <div className=' flex items-center justify-center'>
                      {display_status
                        ? <span className=' bg-green-200 text-green-600 rounded-lg px-2 py-1 text-xs font-semibold'>Active</span>
                        : <span className=' bg-red-200 text-red-600 rounded-lg px-2 py-1 text-xs font-semibold'>In-active</span>
                      }
                    </div>
                  </td>
                  <td className="border-dashed border-t border-gray-200 firstName">
                    <div className=' flex items-center justify-center'>
                      <Link to={`/edit-blog/${id}`}>
                        <EditIcon />
                      </Link>
                    </div>
                    {/* <select className="text-sm text-gray-700" value={status} onChange={(e) => handlePostActive(e, id)}>
                      <option value="">--Select Status--</option>
                      <option value="ACTIVE">Active</option>
                      <option value="SUSPENDED">Suspended</option>
                      <option value="ADMIN_DISABLED">Admin disabled</option>
                      <option value="DISABLED">Disabled</option>
                    </select> */}
                  </td>
                </tr>
              })}
            </table>
          </div>
          <div className=" my-7">
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={10}
              pageCount={Math.ceil(blogList.data?.total_count / paramsObject.limit)}
              previousLabel="<"
              renderOnZeroPageCount={null}
              containerClassName='flex items-center justify-center space-x-4'
              pageLinkClassName='pageNumber'
              previousLinkClassName='pageNumber'
              nextLinkClassName='pageNumber'
              activeLinkClassName='selectedPageNumber'
              disabledClassName='lastPage'
              disabledLinkClassName='lastPage'
            />
          </div>
        </>
      }
    </Layout>
  )
}

export default Blogs