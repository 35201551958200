import React from 'react'

function SearchBar({name,value,onChange,placeholder}) {
    return (
        <div>
            <form>
                <div className='flex items-end space-x-4 mt-12'>
                    <div className='flex-1'>
                        <input type="text" 
                            className="border-solid border-2 border-gray-400 py-2 px-2 rounded w-full placeholder:italic placeholder:text-sm"
                            name={name} placeholder={placeholder} value={value} onChange={onChange}></input>
                    </div>
                    <div className='h-full'>
                        <button className='bg-[color:var(--primaryColor)] text-white px-12 py-2 rounded h-full'>Search</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default SearchBar