import axios from "axios";
import { config } from "../config";
import Cookies from "universal-cookie";
const cookies = new Cookies();


export const loginUser = async (data) => {
    var form_data = new FormData();    
    for ( var key in data ) {
        form_data.append(key, data[key]);
    }
    var res = await axios({ method: "POST", url: `${config.apiurl}Api/loginAdmin`, data: data })
    return res
};
