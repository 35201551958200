import { useEffect, useState } from "react"
import { getData } from "../../apis"
import Layout from "../../components/layout"
import Moment from 'react-moment';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import useGetSingleData from "../../hooks/useGetSingleData";
import { Modal } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';

const PostsReport = () => {
    const initialState = {
        screen_name: 'REPORTED_POSTS',
        offset: 10,
        pageNo: 0
    }
    const [postReportData, setPostReportData] = useState([])
    const [dataCount, setDataCount] = useState(0)
    const [formData, setFormData] = useState(initialState)
    const { data, handleGetSingleData } = useGetSingleData()
    const [openUserReportModal, setOpenUserReportModal] = useState(false)
    useEffect(() => {
        getUserData()
    }, [])
    const getUserData = () => {
        getData(formData)
            .then((res) => {
                if (res.status === 200) {
                    setPostReportData(res.data.data)
                    setDataCount(res.count)
                }
            })
            .catch((err) => {
                console.error(err)
            })
    }
    const handleOpenReportModal = (id) => {
        handleGetSingleData(id, postReportData)
        setOpenUserReportModal(true)
    }
    return <>
        <Layout>
            <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mt-8">
                <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                    <thead>
                        <tr className="text-left">
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                Sr.no
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                Post Title
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                Report Count
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                Status
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {postReportData?.map((data, idx) => {
                            const { title, report_count, post_status, post_id } = data
                            return <tr key={idx}>
                                <td className="border-dashed border-t border-gray-200 userId">
                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{idx + 1}</span>
                                </td>
                                <td className="border-dashed border-t border-gray-200 firstName">
                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{title}</span>
                                </td>
                                <td className="border-dashed border-t border-gray-200 lastName">
                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                        {report_count}
                                    </span>
                                </td>
                                <td className="border-dashed border-t border-gray-200 emailAddress">
                                    <span className={`px-5 py-1 rounded text-sm font-semibold inline-block ${post_status === 'PENDING' ? 'text-yellow-700 bg-yellow-200' : post_status === 'ACTIVE' ? 'text-green-700 bg-green-200' : ''}`}>
                                        {post_status}
                                    </span>
                                </td>
                                <td className="border-dashed border-t border-gray-200 emailAddress py-2">
                                    <button onClick={() => handleOpenReportModal(post_id)} className="px-4 py-2 rounded bg-gray-300"><RemoveRedEyeIcon /> View</button>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </Layout>
        <Modal open={openUserReportModal} onClose={() => setOpenUserReportModal(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <div className='answerModal-container h-full'>
                <div className='answerModal-row max-w-6xl mx-auto mt-10'>
                    <div className='bg-white rounded-md px-4 document-wrapper divide-y'>
                        <div className="modalHead document-head py-4">
                            <div className='flex items-center justify-between'>
                                <h2 className=' text-gray-700 text-3xl font-semibold'>User Report</h2>
                                <div className='w-6 h-6 cursor-pointer' onClick={() => setOpenUserReportModal(false)}>
                                    <ClearIcon className='w-full h-full' />
                                </div>
                            </div>
                        </div>
                        <div className=" max-h-[30rem] overflow-auto">
                            <form action="" className=" ">
                                <div className=" py-4 space-y-4">
                                    <div className="grid grid-cols-2">
                                        <div className=" space-y-4">
                                            <div>
                                                <label htmlFor="username" className="label">Post Title</label>
                                                <p className="text">{data.title}</p>
                                            </div>
                                            <div>
                                                <label htmlFor="username" className="label">Created at</label>
                                                <p className="text"><Moment date={data.created_at} fromNow /></p>
                                            </div>
                                            <div>
                                                <label htmlFor="username" className={`label block`}>Status</label>
                                                <p className={`text inline-block rounded`}>{data.post_status}</p>
                                            </div>
                                        </div>
                                        <div className="w-full h-[20rem]">
                                            <video className="w-full h-full" src={data.urls} poster={data.thumb} controls></video>
                                        </div>
                                    </div>
                                </div>
                                <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mt-8">
                                    <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                                        <thead>
                                            <tr className="text-left">
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                    Sr.no
                                                </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                    Reported By
                                                </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                    Reason
                                                </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                    Reported at
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.reporters_list?.map((data, idx) => {
                                                const { reported_by, report_reason, reported_at } = data
                                                return <tr key={idx}>
                                                    <td className="border-dashed border-t border-gray-200 userId">
                                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{idx + 1}</span>
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200 firstName">
                                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{reported_by}</span>
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200 lastName">
                                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                                            {report_reason}
                                                        </span>
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200 emailAddress">
                                                        <span className={`px-5 py-1 rounded text-sm font-semibold inline-block`}>
                                                            <Moment date={reported_at} fromNow />
                                                        </span>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="modalFoot py-4 border-t">
                                    <div className=' space-x-4'>
                                        {/* <button className='px-8 py-2 rounded bg-[color:var(--primaryColor)] text-white'>Save</button> */}
                                        <button type="button" className='px-8 py-2 rounded border' onClick={() => setOpenUserReportModal(false)}>Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </>
}

export default PostsReport