import { useEffect, useState } from "react"
import { getData } from "../../apis"
import { activeDeactiveUser } from "../../apis/users"
import Layout from "../../components/layout"
import ReactPaginate from 'react-paginate';
import SearchBar from "../../components/SearchBar";
import useDebounce from "../../hooks/useDebounce";

const Users = () => {
    const initialState = {
        screen_name: 'USERS',
        offset: 10,
        pageNo: 0,
        username:''
    }

    const [formData, setFormData] = useState(initialState)
    const [userData, setUserData] = useState([])
    const [dataCount, setDataCount] = useState(0)
    const searchDebounce = useDebounce(formData.username,500)
    useEffect(() => {
        getUserData()
    }, [formData.pageNo,searchDebounce])
    const getUserData = () => {
        getData(formData)
            .then((res) => {
                if (res.status === 200) {
                    setUserData(res.data.data)
                    setDataCount(Math.ceil(res.data.count / formData.offset));
                }
            })
            .catch((err) => {
                console.error(err)
            })
    }


    const handleUserActive = (e, id) => {
        let data = {
            screen_id: id,
            status: e.target.value,
            screen_name: "user"
        }
        activeDeactiveUser(data)
            .then((res) => {
                if (res.status === 200) {
                    getUserData()
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const handlePageClick = (val) => {
        let data = {
            ...formData,
            pageNo: val.selected
        }
        setFormData(data)
    }

    const handleSearch = (e) => {
        let value = e.target.value
        let data = {
            ...formData,
            [e.target.name]: value
        }
        setFormData(data)
    }
    return <Layout>
        <SearchBar value={formData.username} name='username' onChange={handleSearch} placeholder='Search By Username'/>
        <div className="overflow-x-auto  bg-white rounded-lg shadow overflow-y-auto relative mt-8">
            <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                <thead>
                    <tr className="text-left">
                        <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                            Sr.no
                        </th>
                        <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                            Username
                        </th>
                        <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                            First Name
                        </th>
                        <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                            Last Name
                        </th>
                        <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                            Status
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {userData?.map((data, idx) => {
                        const { username, first_name, last_name, status, id } = data
                        return <tr key={idx}>
                            <td className="border-dashed border-t border-gray-200 userId">
                                <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{(formData.offset * (formData.pageNo + 1)) - (formData.offset - (idx + 1))}</span>
                            </td>
                            <td className="border-dashed border-t border-gray-200 firstName">
                                <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{username}</span>
                            </td>
                            <td className="border-dashed border-t border-gray-200 firstName">
                                <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{first_name}</span>
                            </td>
                            <td className="border-dashed border-t border-gray-200 firstName">
                                <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{last_name}</span>
                            </td>
                            <td className="border-dashed border-t border-gray-200 firstName">
                                <select className="text-sm text-gray-700" value={status} onChange={(e) => handleUserActive(e, id)}>
                                    <option value="">--Select Status--</option>
                                    <option value="ACTIVE">Active</option>
                                    <option value="SUSPENDED">Suspended</option>
                                    <option value="ADMIN_DISABLED">Admin disabled</option>
                                    <option value="DISABLED">Disabled</option>
                                </select>
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
        <div className=" my-7">
            <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={dataCount}
                previousLabel="<"
                renderOnZeroPageCount={null}
                containerClassName='flex items-center justify-center space-x-4'
                pageLinkClassName='pageNumber'
                previousLinkClassName='pageNumber'
                nextLinkClassName='pageNumber'
                activeLinkClassName='selectedPageNumber'
                disabledClassName='lastPage'
                disabledLinkClassName='lastPage'
            />
        </div>
    </Layout>
}
export default Users