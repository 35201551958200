import React, { useRef } from 'react'
import Layout from '../../components/layout'
import Editor from '../../components/elements/Editor'
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PublishIcon from '@mui/icons-material/Publish';
import Input from '../../components/elements/form/Input';
import { useForm } from 'react-hook-form';
import useCreate from '../../hooks/useCreate';
import { useNavigate } from 'react-router-dom';
import useUploadFile from '../../hooks/useUploadFile';
import spinner from '../../assets/images/loader.gif'
import toast, { Toaster } from 'react-hot-toast';

const CreateBlog = () => {
  const navigate = useNavigate();
  let initialState = {
    title: '',
    description: '',
    seo: {
      title: '',
      description: ''
    },
    content: '',
    image: '',
    media_path: ''
  }
  const { control, handleSubmit, setValue, getValues } = useForm({
    defaultValues: initialState
  });
  const { setDataToServer } = useCreate({
    url: 'create-blog',
    refreshUrl: 'blogsListing',
    onSuccess: (res) => {
      navigate(-1)
      toast.success('Blog Created Successfully')
    },
    onError: (err) => {
      console.log(err);
    }
  })
  const uploadFile = useUploadFile({
    url: 'update-image',
    onSuccess: (res) => {
      setValue('image', res.data.media_id)
      setValue('media_path', res.data.media_path)
      toast.success('Image Added Successfully')
    },
    onError: (err) => {
      console.log(err);
    }
  })


  const uploadRef = useRef(null)

  const handleSubmitBlog = (data) => {
    setDataToServer.mutate(data)
  }

  const handleUploadImage = (e) => {
    let file = e.target.files[0]
    uploadFile.mutate({ file })
  }
  return (
    <>
    {(uploadFile.isLoading || setDataToServer.isLoading) &&
      <div className=' fixed top-0 left-0 w-full h-full bg-[#ffffffbf] flex items-center justify-center z-40'>
        <img src={spinner} alt="spinner" className=' w-8 h-8' />
      </div>}
      <Layout>
      <Toaster />
        <div className='flex items-center justify-between py-3 shadow-lg bg-gray-50 -mx-4 px-4 sticky top-0 z-10'>
          <div className=' space-x-3'>
            <ArrowBackIcon className=' cursor-pointer' onClick={() => navigate(-1)} />
            <span className=' font-bold text-lg'>Create Blog</span>
          </div>
          <div>
            <button onClick={handleSubmit(handleSubmitBlog)} className=' bg-blue-600 text-white px-5 py-2 rounded space-x-2'>
              <PublishIcon />
              <span>
                Publish Blog
              </span>
            </button>
          </div>
        </div>
        <div className=' space-y-10 my-10'>
          <Input
            control={control}
            name='title'
            placeHolder={'Enter Title'}
            label={'Blog Title'}
            rules={{
              required: "Please Add Title",
            }}
          />
          <Input
            control={control}
            name='description'
            placeHolder={'Enter Description'}
            label={'Blog Description'}
            rules={{
              required: "Please Add Description",
            }}
          />
          <div>
            <button onClick={() => {
              uploadRef.current?.click()
            }} className=' border border-blue-600 font-semibold rounded-md px-6 py-2'>
              <div className=' space-x-2'>
                <FileUploadIcon />
                <span>
                  Upload Banner Image
                </span>

              </div>
            </button>
            <input type="file" className='hidden' ref={uploadRef} onChange={handleUploadImage} />


            {getValues('media_path') &&
              <div className=' mt-4'>
                <img src={getValues('media_path')} alt="blog image" />
              </div>
            }
          </div>
          <div className='flex-1 space-y-2'>
            <label htmlFor="Blog Title" className=' font-semibold text-lg'>Blog Content</label>
            <Editor control={control}
              name="content"
              rules={{
                required: "Please Enter header",
              }} />
          </div>
          <div>
            <h1 className=' pb-4 border-b border-gray-200 font-semibold text-2xl'>Search Engine Optimization</h1>
            <div className=' pt-4 space-y-5'>
              <Input
                control={control}
                name='seo.title'
                placeHolder={'Enter Title'}
                label={'Meta Title'}
              />
              <Input
                control={control}
                name='seo.description'
                placeHolder={'Enter Description'}
                label={'Meta Description'}
              />
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default CreateBlog