import React, { useRef, useState } from 'react'
import Layout from '../../components/layout'
import Editor from '../../components/elements/Editor'
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PublishIcon from '@mui/icons-material/Publish';
import Input from '../../components/elements/form/Input';
import { useForm } from 'react-hook-form';
import useCreate from '../../hooks/useCreate';
import { useParams } from 'react-router-dom';
import useRead from '../../hooks/useRead';
import { useNavigate } from 'react-router-dom';
import DeleteBlog from './DeleteBlog';
import DeleteIcon from '@mui/icons-material/Delete';
import EditSlug from './EditSlug';
import spinner from '../../assets/images/loader.gif'
import useUploadFile from '../../hooks/useUploadFile';
import Switch from '@mui/material/Switch';
import toast, { Toaster } from 'react-hot-toast';

const EditBlog = () => {
  const navigate = useNavigate();
  const [isDeleteConfirmModal, setIsDeleteConfirmModal] = useState(false)
  const { id } = useParams()
  let initialState = {
    title: '',
    description: '',
    seo: {
      title: '',
      description: ''
    },
    content: '',
    image: '',
    media_path: '',
    display_status: false
  }
  const { control, handleSubmit, setValue, getValues, watch } = useForm({
    defaultValues: initialState
  });
  const { data: singleBlogPost } = useRead({
    url: 'getSingleBlogPost',
    initialData: {
      id
    },
    onSuccess: (res) => {
      const { content, description, title, seo, image_id, media_path, id,display_status } = res.data
      setValue('content', content)
      setValue('id', id)
      setValue('description', description)
      setValue('title', title)
      setValue('seo', seo)
      setValue('image', image_id)
      setValue('media_path', media_path)
      setValue('display_status', display_status)
    }
  })
  const { setDataToServer } = useCreate({
    url: 'update-blog',
    refreshUrl: 'blogsListing',
    onSuccess: (res) => {
      navigate(-1)
      toast.success('Blog Updated Successfully')
    },
    onError: (err) => {
      console.log(err);
    }
  })
  const uploadFile = useUploadFile({
    url: 'update-image',
    onSuccess: (res) => {
      toast.success('Image Added Successfully')
      setValue('image', res.data.media_id)
      setValue('media_path', res.data.media_path)
    },
    onError: (err) => {
      console.log(err);
    }
  })
  const uploadRef = useRef(null)
  const handleUploadImage = (e) => {
    let file = e.target.files[0]
    uploadFile.mutate({ file })
  }
  const handleSubmitBlog = (data) => {
    setDataToServer.mutate(data)
  }

  const handleChangeStatus = (e) => {
    setValue('display_status', e.target.checked)
    if(e.target.checked){
      setValue('published_at', new Date())
    }else{
      setValue('published_at', '')
    }
  }
  return (
    <>
      {(singleBlogPost.isLoading || setDataToServer.isLoading || uploadFile.isLoading) &&
        <div className=' fixed top-0 left-0 w-full h-full bg-[#ffffffbf] flex items-center justify-center z-40'>
          <img src={spinner} alt="spinner" className=' w-8 h-8' />
        </div>}
        <Toaster />
      <Layout>
        <div className='flex items-center justify-between py-3 shadow-lg bg-gray-50 -mx-4 px-4 sticky top-0 z-10'>
          <div className=' space-x-3'>
            <ArrowBackIcon className=' cursor-pointer' onClick={() => navigate(-1)} />
            <span className=' font-bold text-lg'>Update Blog</span>
          </div>
          <div className=' space-x-6 flex'>
            <button onClick={handleSubmit(handleSubmitBlog)} className=' bg-blue-600 text-white px-5 py-2 rounded space-x-2'>
              <PublishIcon />
              <span>
                Publish Blog
              </span>
            </button>
            <button onClick={() => setIsDeleteConfirmModal(true)} className=' bg-red-600 text-white px-5 py-2 rounded space-x-2'>
              <DeleteIcon />
              <span>
                Delete Blog
              </span>
            </button>
            <div>
            <span className=' font-semibold text-sm'>In-active</span>
              <Switch
                checked={watch('display_status')}
                onChange={handleChangeStatus}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <span className=' font-semibold text-sm'>Active</span>
            </div>
          </div>
        </div>
        <div className=' space-y-10 my-10'>
          <Input
            control={control}
            name='title'
            placeHolder={'Enter Title'}
            label={'Blog Title'}
            rules={{
              required: "Please Add Title",
            }}
          />
          <Input
            control={control}
            name='description'
            placeHolder={'Enter Description'}
            label={'Blog Description'}
            rules={{
              required: "Please Add Description",
            }}
          />
          <div>
            <button onClick={() => {
              uploadRef.current?.click()
            }} className=' border border-blue-600 font-semibold rounded-md px-6 py-2'>
              <div className=' space-x-2'>
                <FileUploadIcon />
                <span>
                  Upload Banner Image
                </span>
              </div>
            </button>
            <input type="file" className='hidden' ref={uploadRef} onChange={handleUploadImage} />
            {getValues('media_path') &&
              <div className=' mt-4'>
                <img src={getValues('media_path')} alt="blog image" />
              </div>
            }
          </div>

          <div className='flex-1 space-y-2'>
            <label htmlFor="Blog Title" className=' font-semibold text-lg'>Blog Content</label>
            <Editor control={control}
              name="content"
              rules={{
                required: "Please Enter header",
              }} />
          </div>
          <div>
            <h1 className=' pb-4 border-b border-gray-200 font-semibold text-2xl'>Search Engine Optimization</h1>
            <div className=' pt-4 space-y-5'>
              <Input
                control={control}
                name='seo.title'
                placeHolder={'Enter Title'}
                label={'Meta Title'}
              />
              <Input
                control={control}
                name='seo.description'
                placeHolder={'Enter Description'}
                label={'Meta Description'}
              />
            </div>
          </div>
          {/* <div>
            <h1 className=' pb-4 border-b border-gray-200 font-semibold text-2xl'>Slug</h1>
            <div className=' pt-4 space-y-5 flex items-end space-x-6'>
              <p className='border-solid border-2 py-2 px-2 rounded w-[40%] placeholder:italic placeholder:text-sm bg-gray-300/20 cursor-not-allowed'>
                {singleBlogPost.data?.data?.slug}
              </p>
              <button onClick={() => setIsEditConfirmModal(true)} type='button' className=' px-5 py-2 bg-red-600 text-white w-36 rounded'>Edit Slug</button>
            </div>
          </div> */}
        </div>
        {/* <EditSlug open={isEditConfirmModal} onClose={() => setIsEditConfirmModal(false)} /> */}
        <DeleteBlog id={id} open={isDeleteConfirmModal} onClose={() => setIsDeleteConfirmModal(false)} />
      </Layout>
    </>
  )
}

export default EditBlog