import "./login.scss";
import React, { useState } from "react";
import Cookies from "universal-cookie";
import { Navigate, useNavigate } from "react-router-dom";
import spinner from "../../assets/images/loader.gif";
import { loginUser } from "../../apis/auth";
import logo from "../../assets/images/logo.png";

const cookies = new Cookies();
function Login() {
  let token = cookies.get("_res_token");
  let navigate = useNavigate();
  let initialData = {
    email: "",
    password: "",
  };
  const [userData, setuserData] = useState(initialData);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleSetUserData = (e) => {
    let value = e.target.value;
    setuserData({
      ...userData,
      [e.target.name]: value,
    });
  };
  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    loginUser(userData)
      .then((res) => {
        if (res.status === 200) {
          cookies.set("_res_token", res.data.token, { path: "/" });
          navigate("/", { replace: true });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError(true);
      });
  };

  if (token) return <Navigate to="/" replace />;
  return (
    <>
      <div className="flex flex-col items-center justify-center min-h-screen py-2 bg-gray-100">
        <div className="flex flex-col items-center justify-center w-full flex-1 px-20 ">
          <div className="bg-white rounded-2xl shadow-2xl flex w-2/3 max-w-4xl">
            <div className="w-3/5 py-20 px-9">
              <p className=" text-3xl font-semibold mb-4 roboto">Login</p>
              <form
                onSubmit={handleSubmit}
                className=" space-y-4"
                autoComplete="off"
              >
                <div>
                  <label htmlFor="email" className=" block text-xl roboto">
                    Email
                  </label>
                  <input
                    type="text"
                    required
                    autoComplete="email"
                    className="border-solid border-2 border-gray-400 py-2 px-2 rounded w-full mt-2 placeholder:italic placeholder:text-sm"
                    name="email"
                    placeholder="Enter your email here"
                    value={userData.email}
                    onChange={handleSetUserData}
                  ></input>
                </div>
                <div>
                  <label htmlFor="password" className=" block text-xl roboto">
                    Password
                  </label>
                  <input
                    type="password"
                    autoComplete="current-password"
                    required
                    className="border-solid border-2 border-gray-400 py-2 px-2 rounded w-full mt-2 placeholder:italic placeholder:text-sm"
                    name="password"
                    placeholder="Enter your password here"
                    value={userData.password}
                    onChange={handleSetUserData}
                  ></input>
                </div>
                {error && (
                  <div className="text-red-700 text-center">
                    <small>Email or password is incorrect</small>
                  </div>
                )}
                {isLoading ? (
                  <div className=" w-8 h-8 mx-auto">
                    <img
                      src={spinner}
                      alt="spinner"
                      className="w-full h-full"
                    />
                  </div>
                ) : (
                  <div className="button">
                    <button
                      type="submit"
                      className=" w-full py-2 bg-[color:var(--primaryColor)] text-white rounded roboto"
                    >
                      Login
                    </button>
                  </div>
                )}
              </form>
            </div>
            <div className="w-2/5 rounded-r-2xl rounded-br-2xl py-32 s text-center ">
              <p className=" roboto text-sl font-semibold text-[color:var(--primaryColor)]">
                Welcome To{" "}
                <span className="font-extrabold">Respondo Dashboard</span>
              </p>
              <div className=" flex justify-center mt-8 w-32 h-32 mx-auto">
                <img src={logo} alt="logo" className="w-full h-full" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
