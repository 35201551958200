import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import FeedbackOutlinedIcon from "@mui/icons-material/FeedbackOutlined";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import TheatersIcon from "@mui/icons-material/Theaters";
import { Link } from "react-router-dom";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { DownloadSharp } from "@mui/icons-material";

const cookies = new Cookies();
const Sidebar = () => {
  const { dispatch } = useContext(DarkModeContext);
  const navigate = useNavigate();
  const handleLogout = () => {
    cookies.remove("_res_token");
    navigate("/login", { replace: true });
  };
  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/" style={{ textDecoration: "none" }}>
          <span className="logo">Respondo</span>
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
          <p className="title">MAIN</p>
          <Link to="/" style={{ textDecoration: "none" }}>
            <li>
              <DashboardIcon className="icon" />
              <span>Dashboard</span>
            </li>
          </Link>
          <p className="title">USER</p>
          <Link to="/users" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Users</span>
            </li>
          </Link>
          <Link to="/feedbacks" style={{ textDecoration: "none" }}>
            <li>
              <FeedbackOutlinedIcon className="icon" />
              <span>Feedbacks</span>
            </li>
          </Link>
          <Link to="/posts" style={{ textDecoration: "none" }}>
            <li>
              <TheatersIcon className="icon" />
              <span>Posts</span>
            </li>
          </Link>
          <Link to="/blogs" style={{ textDecoration: "none" }}>
            <li>
              <NoteAddIcon className="icon" />
              <span>Blogs</span>
            </li>
          </Link>
          {/* <Link to="/outsourced" style={{ textDecoration: "none" }}>
            <li>
              <DownloadSharp className="icon" />
              <span>Outsourced</span>
            </li>
          </Link> */}
          <Link to="/reported-users" style={{ textDecoration: "none" }}>
            <li>
              <ReportOutlinedIcon className="icon" />
              <span>Reported users</span>
            </li>
          </Link>
          <Link to="/reported-posts" style={{ textDecoration: "none" }}>
            <li>
              <SummarizeOutlinedIcon className="icon" />
              <span>Reported posts</span>
            </li>
          </Link>
          <li onClick={handleLogout}>
            <ExitToAppIcon className="icon" />
            <span>Logout</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
