import { useMutation, useQueryClient } from "react-query";
import axios from "../apis/axios";
import Cookies from "universal-cookie";
import { config } from "../config";

const cookies = new Cookies();
const useUploadFile = ({ url = "", onSuccess, onError }) => {
  const queryClient = useQueryClient();
  const uploadFile = useMutation((data) => setUsers(data), {
    onError: (err) => {
      onError?.(err);
    },
    onSuccess: (data) => {
      onSuccess?.(data);
    },
  });
  const setUsers = async (getFormData) => {
    let fd = new FormData();
    let token = cookies.get("_res_token");
    for (var key in getFormData) {
      fd.append(key, getFormData[key]);
    }
    fd.append("token", JSON.stringify(token));
    fd.append("identity", config.identity);
    const response = await axios({
      method: "POST",
      data: fd,
      headers: {
        Authorization: `Bearer ${token.token}`,
      },
      url: `/${url}`,
    });
    return response.data;
  };
  return uploadFile;
};

export default useUploadFile;
