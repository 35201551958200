import React from 'react'
import { Controller } from 'react-hook-form'

const Input = ({
    name,
    control,
    label,
    placeHolder,
    rules = {}
}) => {
    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
                <>
                    <div className='flex-1 space-y-2'>
                        <label htmlFor="Blog Title" className=' font-semibold text-lg'>{label}</label>
                        <input
                            type="text"
                            value={value}
                            onChange={onChange}
                            className={`border-solid border-2 py-2 px-2 rounded w-full placeholder:italic placeholder:text-sm ${error ? 'border-red-600' : 'border-gray-400'}`}
                            placeholder={placeHolder}
                        />
                    {error &&
                        <small className="text-red-600 font-medium block">{error.message}</small>}
                    </div>
                </>

            )}

        />
    )
}

export default Input