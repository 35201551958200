import React, { useRef } from 'react';
// import { Editor as BlogEditot } from '@tinymce/tinymce-react';
import BundledEditor from './BundledEditor'
import { Controller } from 'react-hook-form'

function Editor({ control, name, rules }) {
    const editorRef = useRef(null);
    return (
        <>
            <Controller
                control={control}
                name={name}
                rules={rules}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <>
                        <BundledEditor
                            apiKey="c6f6qhnjgwn7t2oypmfu3vibhkvmdm50btv5u8ykymkuq5a6"
                            value={value}
                            onEditorChange={(newvalue, editor) => {
                                onChange(newvalue, editor.getContent({ format: "html" }));
                            }}
                            init={{
                                height: 500,
                                menubar: false,
                                plugins: [
                                    'advlist', 'anchor', 'autolink', 'help', 'image', 'link', 'lists',
                                    'searchreplace', 'table', 'wordcount'
                                ],
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                menubar: 'favs file edit view insert format tools table help',
                                toolbar: 'undo redo | blocks | ' +
                                    'bold italic forecolor link | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                    'removeformat | help',
                                branding: false,
                                noneditable_class: 'mceNonEditable',
                            }}
                        />
                        {error && <small className="text-red-600 font-medium block  ">{error.message}</small>}
                    </>
                )}
            />
        </>
    )
}

export default Editor