import { useState } from "react"

function useGetSingleData() {
    const [data, setData] = useState({})
    const handleGetSingleData = (id, array) => {
        setData(array.find(data => data.id === id))
    }
    return { data, handleGetSingleData }
}

export default useGetSingleData