import axios from "axios";
import { config } from "../config";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export const getDashboardData = async () => {
  let token = cookies.get("_res_token");
  var form_data = new FormData();
  form_data.append("token", JSON.stringify(token));
  form_data.append("identity", config.identity);
  var res = await axios({
    method: "POST",
    data: form_data,
    headers: {
      Authorization: `Bearer ${token.token}`,
    },
    url: `${config.apiurl}AdminPanel/getDashboardData`,
  });
  return res;
};

export const getData = async (data) => {
  let token = cookies.get("_res_token");
  var form_data = new FormData();
  form_data.append("token", JSON.stringify(token));
  form_data.append("identity", config.identity);
  for (var key in data) {
    form_data.append(key, data[key]);
  }
  var res = await axios({
    method: "POST",
    data: form_data,
    headers: {
      Authorization: `Bearer ${token.token}`,
    },
    url: `${config.apiurl}AdminPanel/getData`,
  });
  return res;
};
