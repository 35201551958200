import "./widget.scss";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';

const Widget = ({ type,count }) => {
  let data;

  //temporary
  const amount = 100;
  switch (type) {
    case "user":
      data = {
        title: "TOTAL USERS",
        link: "See all users",
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgb(194 24 30 / 20%)",
              color: "var(--primaryColor)",
              width: '2rem',
              height : '2rem'
            }}
          />
        ),
      };
      break;
    case "posts":
      data = {
        title: "TOTAL POSTS",
        link: "View all posts",
        icon: (
          <ArticleOutlinedIcon
            className="icon"
            style={{ 
              backgroundColor: "rgb(194 24 30 / 20%)",
              color: "var(--primaryColor)",
            width: '2rem',
              height : '2rem'
          }}
          />
        ),
      };
      break;
    case "new_user":
      data = {
        title: "New User",
        link: "See details",
        icon: (
          <FavoriteBorderOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgb(194 24 30 / 20%)",
              color: "var(--primaryColor)",
              width: '2rem',
              height : '2rem'
            }}
          />
        ),
      };
      break;
    case "most_views":
      data = {
        title: "MOST VIEWED VIDEO",
        link: "See details",
        icon: (
          <RemoveRedEyeOutlinedIcon
            className="icon w-8 h-8"
            style={{
              backgroundColor: "rgb(194 24 30 / 20%)",
              color: "var(--primaryColor)",
              width: '2rem',
              height : '2rem'
            }}
          />
        ),
      };
      break;
    case "pending_feedbacks":
      data = {
        title: "PENDING FEEDBACKS",
        link: "See details",
        icon: (
          <FeedbackOutlinedIcon
            className="icon w-8 h-8"
            style={{
              backgroundColor: "rgb(194 24 30 / 20%)",
              color: "var(--primaryColor)",
              width: '2rem',
              height : '2rem'
            }}
          />
        ),
      };
      break;
    case "most_followed":
      data = {
        title: "MOST FOLLOWED USER",
        link: "See details",
        icon: (
          <PersonOutlinedIcon
            className="icon w-8 h-8"
            style={{
              backgroundColor: "rgb(194 24 30 / 20%)",
              color: "var(--primaryColor)",
              width: '2rem',
              height : '2rem'
            }}
          />
        ),
      };
      break;
    case "followed_user":
      data = {
        title: "MOST FOLLOWED USER",
        link: "See details",
        icon: (
          <GroupAddOutlinedIcon
            className="icon w-8 h-8"
            style={{
              backgroundColor: "rgb(194 24 30 / 20%)",
              color: "var(--primaryColor)",
              width: '2rem',
              height : '2rem'
            }}
          />
        ),
      };
      break;
    default:
      break;
  }

  return (
    <div className="widget">
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">
          {count}
        </span>
        {/* <span className="link">{data.link}</span> */}
      </div>
      <div className="right">
        {data.icon}
      </div>
    </div>
  );
};

export default Widget;
