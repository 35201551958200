import axios from "axios";
import { config } from "../config";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export const activeDeactiveUser = async (data) => {
  const { screen_id, screen_name, status } = data;
  let token = cookies.get("_res_token");
  var form_data = new FormData();
  form_data.append("token", JSON.stringify(token));
  form_data.append("identity", config.identity);
  form_data.append("screen_id", screen_id);
  form_data.append("action", status);
  form_data.append("screen_name", screen_name);
  var res = await axios({
    method: "POST",
    data: form_data,
    headers: {
      Authorization: `Bearer ${token.token}`,
    },
    url: `${config.apiurl}AdminPanel/changeStatus`,
  });
  return res;
};
